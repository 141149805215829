<template>
  <div class="wrapper">
    <notifications></notifications>
    <side-bar>
      <template slot="links">
        <!-- <sidebar-item
          :link="{
            name: 'Dashboards',
            icon: 'ni ni-shop text-primary',
          }"
        >
          <sidebar-item
            :link="{ name: 'Dashboard', path: '/dashboard' }"
          ></sidebar-item>
        </sidebar-item> -->

        <sidebar-item
          :link="{
            name: 'Dashboard',
            icon: 'ni ni-shop text-primary',
            path: '/dashboard'
          }">
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Users',
            icon: 'ni ni-archive-2 text-green',
            path: '/users'
          }">
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Profile',
            icon: 'ni ni-archive-2 text-green',
            path: '/user/me'
          }">
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Media Library',
            icon: 'ni ni-archive-2 text-green',
            path: '/media-library'
          }">
        </sidebar-item>

        <sidebar-item :link="{
                  name: 'Inject Library',
                  icon: 'ni ni-ungroup text-orange'
                  }">
          <sidebar-item v-for="widget in libraryWidgets" :key="widget.slug" :link="{ name: widget.title, path: `/library/${widget.slug}` }"/>

        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Editor',
            icon: 'ni ni-archive-2 text-green',
            path: '/editorjs'
          }">
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Showcase',
            icon: 'ni ni-archive-2 text-green',
            path: '/showcase/dashboard'
          }">
        </sidebar-item>

      </template>

      <!-- <template slot="links-after">
        <hr class="my-3">
        <h6 class="navbar-heading p-0 text-muted">Documentation</h6>

        <b-nav class="navbar-nav mb-md-3">
          <b-nav-item
               href="https://www.creative-tim.com/learning-lab/bootstrap-vue/quick-start/argon-dashboard"
               >
              <i class="ni ni-spaceship"></i>
              <b-nav-text class="p-0">Getting started</b-nav-text>
          </b-nav-item>
          <b-nav-item href="https://www.creative-tim.com/learning-lab/bootstrap-vue/colors/argon-dashboard">
              <i class="ni ni-palette"></i>
              <b-nav-text class="p-0">Foundation</b-nav-text>
          </b-nav-item>
          <b-nav-item href="https://www.creative-tim.com/learning-lab/bootstrap-vue/avatar/argon-dashboard">
              <i class="ni ni-ui-04"></i>
              <b-nav-text class="p-0">Components</b-nav-text>
          </b-nav-item>
          <b-nav-item href="https://www.creative-tim.com/learning-lab/bootstrap-vue/charts/argon-dashboard">
              <i class="ni ni-chart-pie-35"></i>
              <b-nav-text class="p-0">Plugins</b-nav-text>
          </b-nav-item>
        </b-nav>
      </template> -->
    </side-bar>
    <div class="main-content">
      <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>

      <div @click="$sidebar.displaySidebar(false)">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
  /* eslint-disable no-new */
  import PerfectScrollbar from 'perfect-scrollbar';
  import 'perfect-scrollbar/css/perfect-scrollbar.css';

  function hasElement(className) {
    return document.getElementsByClassName(className).length > 0;
  }

  function initScrollbar(className) {
    if (hasElement(className)) {
      new PerfectScrollbar(`.${className}`);
    } else {
      // try to init it later in case this component is loaded async
      setTimeout(() => {
        initScrollbar(className);
      }, 100);
    }
  }

  import DashboardNavbar from './SimEOCDashboardNavbar.vue';
  import ContentFooter from './ContentFooter.vue';
  import { FadeTransition } from 'vue2-transitions';

  import * as _ from 'lodash';

  export default {
    components: {
      DashboardNavbar,
      ContentFooter,
      FadeTransition
    },
    computed: {
      libraryWidgets() {
        const that = this;
        return _.filter(that.widgets, (widget) => {
          return widget.library;
        });
      }
    },
    methods: {
      initScrollbar() {
        let isWindows = navigator.platform.startsWith('Win');
        if (isWindows) {
          initScrollbar('sidenav');
        }
      }
    },
    mounted() {
      const that = this;

      this.initScrollbar();
      setTimeout(function() {
          that.$socket.emit('register', that.$store.state.user.user._id);
      }, 5000);
    }
  };
</script>
<style lang="scss">
</style>
