<template>
  <span v-if="simcell.name">{{ simcell.name }}</span>
</template>

<script>

export default {
  name: "SimcellInfo",
  mixins: [],
  components: {},
  props: {
    simcellId: {
      type: String,
      required: true,
    },
  },
  data: function () {
    return {
      simcell: {},
    };
  },
  computed: {},
  created: function () {
    this.get();
  },
  mounted: function () {},
  methods: {
    get: function () {
      const that = this;
      that.$http.get(`/sim-cell/${that.simcellId}`).then((response) => {
        that.simcell = response.data;
      });
    },
  },
};
</script>
