<template>
    <div>

        <b-card class="mb-2" v-if="canShow && isItemVisible(exercise, $store.state.sim.role, item)">
            <b-card-title class="text-right">
                <b-badge variant="info" class="mr-1">
                    {{ item.priority }}
                </b-badge>
                <b-badge class="mr-1">
                    {{ item.status }}
                </b-badge>

                <ShowSendTimeBadge
                    class="float-right"
                    :exercise="exercise"
                    :item="item"
                />
            </b-card-title>
            <b-card-text>
                {{ item.task }}
            </b-card-text>

            <b-card-text>
                <b-badge>
                    <RoleInfo v-if="inbox == 'out' && item.toRole" :role-id="item.toRole" />
                    <RoleInfo v-if="inbox == 'in' && item.fromRole" :role-id="item.fromRole" />
                </b-badge>
            </b-card-text>

            <!-- <b-card-text>
                <ShowObject :input="item" :cols="['task', 'toRole', 'fromRole', 'status']" />
            </b-card-text> -->

            <b-card-text v-if="item.response">
                <b-card bg-variant="light">{{item.response}}</b-card>
            </b-card-text>

            <b-card-text v-if="isItemActionVisible(exercise, $store.state.sim.role, item) && showButtons()">
                <b-container fluid>
                    <b-row no-gutters>
                        <b-col></b-col>
                        <b-col cols="auto" v-if="showButton('send')">
                            <b-button
                                variant="outline-success"
                                class="mx-1"
                                @click="updateFromRoleStatus('sent')"
                            >
                                Send
                            </b-button>
                        </b-col>
                        <b-col cols="auto" v-if="showButton('edit')">
                            <b-button
                                variant="outline-info"
                                class="mx-1"
                                @click="$emit('editinexercise', item)"
                            >
                                Edit
                            </b-button>
                        </b-col>
                        <b-col cols="auto" v-if="showButton('cancel')">
                            <b-button
                                variant="outline-danger"
                                class="mx-1"
                                @click="updateFromRoleStatus('canceled')"
                            >
                                Cancel
                            </b-button>
                        </b-col>
                        <b-col cols="auto" v-if="showButton('accept')">
                            <b-button
                                variant="outline-success"
                                class="mx-1"
                                @click="updateToRoleStatus('accepted')"
                            >
                                Accept
                            </b-button>
                        </b-col>
                        <b-col cols="auto" v-if="showButton('complete')">
                            <b-button
                                variant="outline-success"
                                class="mx-1"
                                @click="updateToRoleStatus('completed')"
                            >
                                Completed
                            </b-button>
                        </b-col>
                        <b-col cols="auto" v-if="showButton('decline')">
                            <b-button
                                variant="outline-danger"
                                class="mx-1"
                                @click="updateToRoleStatus('declined')"
                            >
                                Decline
                            </b-button>
                        </b-col>
                        <b-col cols="auto" v-if="showButton('clarify')">
                            <b-button
                                variant="outline-info"
                                class="mx-1"
                                @click="updateToRoleStatus('clarify')"
                            >
                                Clarify
                            </b-button>
                        </b-col>
                        <b-col cols="auto" v-if="showButton('forward')">
                            <b-button
                                variant="outline-warning"
                                class="mx-1"
                                @click="updateToRoleStatus('forwarded')"
                            >
                                Forward
                            </b-button>
                        </b-col>
                        <b-col></b-col>
                    </b-row>
                </b-container>
            </b-card-text>
        </b-card>

        <b-modal
            :id="`decline-modal-${item._id}`"
            ref="modal"
            title="Reason for declining!"
            centered
            @ok="handleOk"
            >
                <form ref="form" @submit.stop.prevent="handleSubmit">
                    <b-form-group id="task-group" label="Reason:" label-for="reason">
                        <b-form-textarea
                            id="reason"
                            v-model="response"
                            placeholder="Enter reason"
                            rows="6"
                        ></b-form-textarea>
                    </b-form-group>
                </form>
        </b-modal>
    </div>
</template>

<script>
import { HTTP } from "@/util/http-common";
import HandleErrors from "@/util/handle-errors";

import * as _ from 'lodash';

import SocketIOMixin from '@/util/socketio';
export default {
  name: "Task",
  mixins: [SocketIOMixin],
  components: {},
  data: function() {
    return {
      item: {},
      endStatus: ['declined', 'forwarded', 'canceled', 'completed'],
      response: ''
    };
  },
  props: {
    input: {
      type: Object,
      required: false,
    },
    id: {
      type: String,
      required: false,
    },
    exercise: {
      type: Object,
      required: false,
      default: Object,
    },
    inbox: {
        type: String,
        required: false,
        default: "in",
        enum: ["in", "out"]
    }
  },
  computed: {
      canShow: function() {

        // If this is my inbox, then only show things sent to this position.
        if(this.inbox == "in" && this.item.toRole == this.$store.state.sim.role.role) {
            if(this.item.status == 'draft' || this.item.status == 'canceled') {
                return false;
            } else {
                return true;
            }
        } else if(this.inbox == "out" && this.item.fromRole == this.$store.state.sim.role.role) {
            return true;
        }

        return false;
        // return true;


        //   // If these are tasks sent to this role, don't show drafts.
        //   if(this.inbox == "in" && this.item.status == "draft") {
        //       return false;
        //   } else {// For tasks this user sent, show all of them.
        //     return true;
        //   }
      }
  },
  created() {
    if (this.id) {
      this.init();
    } else if (this.input) {
      this.item = this.input;
    }
  },
  watch: {
    id: function(val) {
      if (val) {
        this.init();
      }
    },
    input: function(val) {
      if (val) {
        this.item = val;
      }
    },
  },
  methods: {
    init() {
      const that = this;
      that.$http.get(`/exercise-mission-task/${this.id}`)
        .then((response) => {
          that.item = response.data;
        })
        .catch((error) => {
          HandleErrors.HandleErrors(error);
        });
    },
    async updateFromRoleStatus(status) {
      const that = this;

      // Clone the current task.
      const currentItem = _.clone(that.item);
      const newItem = _.clone(that.item);

      await that.$http.patch(`/exercise-mission-task/${that.item._id}`, {
          actionTime: that.exercise.currentTime
      });

      newItem._id = undefined;
      newItem.status = status;
      newItem.sendTime = that.exercise.currentTime;

      await that.$http.post(`/exercise-mission-task`, newItem);
    },
    handleOk() {
        this.updateToRoleStatus('declined');
        this.$bvModal.hide(`decline-modal-${this.item._id}`);
    },
    async updateToRoleStatus(status) {
      const that = this;

      if(status == "declined" && !that.response.trim()) {
        this.$bvModal.show(`decline-modal-${that.item._id}`);
        return;
      }

      // Clone the current task.
      const currentItem = _.clone(that.item);
      const newItem = _.clone(that.item);

      await that.$http.patch(`/exercise-mission-task/${that.item._id}`, {
          actionTime: that.exercise.currentTime
      });

      newItem._id = undefined;
      newItem.status = status;
      newItem.response = that.response;
      newItem.sendTime = that.exercise.currentTime;

      await that.$http.post(`/exercise-mission-task`, newItem);
    },
    // canCShow() {
    //     // 'draft', 'sent', 'accepted', 'declined', 'clarify', 'forwarded', 'canceled', 'completed'
    //     // end: 'declined', 'forwarded', 'canceled', 'completed'
    //     // if(this.item.actionTime) {
    //     //     return false;
    //     // } else {// Show if no action time regardless of status.
    //     //     return true;
    //     // }

    //     // if(['draft'].indexOf(that.item.status) >= 0) {
    //     //     return true;
    //     // }

        


    //     const roleId = this.$store.getters['sim/getRoleID'];
    //     if(this.inbox == "out") {
    //         if(roleId == this.item.toRole && ['draft'].indexOf(this.item.status) >= 0) {
    //             return true;
    //         }
    //     } else {

    //     }



    //     return false;
    // },
    showButtons() {
        if(this.endStatus.indexOf(this.item.status) >= 0) {
            return false;
        }

        return true;
    },
    showButton(button) {
        // If this is the inbox.
        if(this.inbox == "in") {
            if(this.item.status == "sent" && ["accept", "decline", "clarify"].indexOf(button) >= 0) {
                return true;
            } else if(this.item.status == "accepted" && ["complete", "decline"].indexOf(button) >= 0) {
                return true;
            }
        } else {
            if(this.item.status == "draft" && ["send", "edit", "cancel"].indexOf(button) >= 0) {
                return true;
            } else if(this.item.status == "clarify" && ["send", "edit", "cancel"].indexOf(button) >= 0) {
                return true;
            }
        }

        return false;
    }
  },
};
</script>
