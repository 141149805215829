<template>
  <div v-if="canShowAtCurrentTime(exercise, item) && canShowRole()">
    <!-- <div> -->
    <b-card
      class="mb-2"
      :bg-variant="
        item.primaryUser === $store.state.user.user._id ? 'light' : ''
      "
    >
      <b-card-title>
        {{ item.name }}
         <!-- - {{ item._id }} ({{ item.simcell }}) - -->
        <span class="tw-float-right">
          <b-badge v-if="item.simcell" class="tw-mr-2">
            <UserFullName v-if="item.primaryUser" :user-id="item.primaryUser" />
          </b-badge>
          <b-badge variant="primary" v-if="item.simcell">
            <SimcellInfo :simcell-id="item.simcell" />
          </b-badge>
          <i class="fas fa-2x fa-star" v-if="item.controller"></i>
          <ShowSendTimeBadge
                class="float-right mr-2"
                :exercise="exercise"
                :item="item"
                />
        </span>
      </b-card-title>

      <b-card-text>
        <!-- {{ item._id }} / {{ item.customer }} / {{ item.exercise }}
        <hr /> -->
        {{ item.description }}
      </b-card-text>

      <b-card-text v-if="item.permissions">
        <b-badge
          class="mr-1"
          v-for="permission in item.permissions"
          :key="permission.slug"
        >
          {{ permission.name }}
        </b-badge>
      </b-card-text>

      <!-- <b-card-text>
        <ShowObject
          :input="item"
          :cols="['_id', 'name', 'role', 'actionTime', 'sendTime']"
        />
      </b-card-text> -->

      <!-- <template v-slot:footer>
        <ItemButtonOptions :item="item" />
      </template> -->

      <b-card-text>
        <b-container fluid>
          <b-row no-gutters>
            <!-- <b-col cols="auto" v-if="item.simcell">
                  <b-button
                        variant="outline-success"
                        class="mx-1"
                        disabled
                    >
                        <SimcellInfo :simcell-id="item.simcell" />
                    </b-button>
                </b-col> -->
            <b-col class="text-right" v-if="!isExerciseController()"></b-col>
            
            <b-col class="text-right" v-if="!exercise._id || (isExerciseNotClosed(exercise) && isExerciseController() && !item.primaryUser)">
              <ItemButtonOptions
                :widget="$options.name"
                :item="item"
                :exercise="exercise"
                v-on:details="$emit('details', $event)"
                v-on:edit="$emit('edit', $event)"
                v-on:duplicate="$emit('duplicate', $event)"
                v-on:removefromexercise="remove($event)"
                v-on:editinexercise="$emit('editinexercise', $event)"
                v-on:addtoexercise="$emit('addtoexercise', $event)"
              />
            </b-col>
            <b-col cols="auto" v-if="exercise._id">
              <b-button
                  v-if="isExerciseNotClosed(exercise) && isExerciseController() && item.primaryUser"
                  variant="outline-success"
                  class="mx-1"
                  @click="$emit('editinexercise', item)"
              >
                  <i class="fas fa-edit"></i> Edit
              </b-button>
              <b-button
                variant="outline-success"
                class="mx-1"
                @click="$emit('selectrole', item)"
                v-if="isExerciseNotClosed(exercise) && item.exercise && !item.primaryUser && !$store.state.sim.role._id"
              >
                <i class="fas fa-handPointer"></i> Select Position
              </b-button>
              <b-button
                variant="outline-warning"
                class="mx-1"
                @click="$emit('leaverole', item)"
                v-if="isExerciseNotClosed(exercise) && item.exercise && item.primaryUser && item.primaryUser == $store.state.user.user._id"
              >
                <i class="fas fa-handPointer"></i> Leave Position
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import { HTTP } from "@/util/http-common";
import HandleErrors from "@/util/handle-errors";

import SocketIOMixin from '@/util/socketio';
export default {
  name: "Role",
  mixins: [SocketIOMixin],
  components: {},
  data: function () {
    return {
      item: {},
    };
  },
  props: {
    input: {
      type: Object,
      required: false,
    },
    id: {
      type: String,
      required: false,
    },
    exercise: {
      type: Object,
      required: false,
      default: Object,
    },
  },
  created() {
    if (this.id) {
      this.init();
    } else if (this.input) {
      this.item = this.input;
    }
  },
  watch: {
    id: function (val) {
      if (val) {
        this.init();
      }
    },
    input: function (val) {
      if (val) {
        this.item = val;
      }
    },
  },
  methods: {
    init() {
      const that = this;
      that.$http.get(`/role/${this.id}`)
        .then((response) => {
          that.item = response.data;
        })
        .catch((error) => {
          HandleErrors.HandleErrors(error);
        });
    },
    remove: async function () {
      const that = this;

      await that.$http.patch(`/role/${that.item._id}`, {
        status: "deleted",
        actionTime: that.exercise.currentTime
      });

      that.$notify({
        text: `Role '${that.item.name}' removed from exercise.`,
        duration: 10000,
        type: "success",
      });

      that.$emit("removefromexercise", that.item);
    },
    showLeaveRoleButton: function () {
      if (
        this.item.primaryUser &&
        this.item.primaryUser == this.$store.state.user.user._id
      ) {
        return true;
      } else {
        return false;
      }
    },
    canShowRole: function() {
      return !this.item.controller || (this.item.controller && !this.item.actionTime);
      // return !this.item.controller || (this.item.controller);
    }
  },
};
</script>
