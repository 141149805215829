<template>
  <div class="mt-2">
    <Simcell
      v-for="item in itemList"
      :key="item._id"
      :input="item"
      :exercise="exercise"
      v-on:details="$emit('details', $event)"
      v-on:edit="$emit('edit', $event)"
      v-on:duplicate="$emit('duplicate', $event)"
      v-on:addtoexercise="$emit('addtoexercise', $event)"
      view="summary"
    />
  </div>
</template>

<script>
import { HTTP } from "@/util/http-common";
import HandleErrors from "@/util/handle-errors";

import { mapState } from "vuex";

import SocketIOMixin from '@/util/socketio';
export default {
  name: "SimcellLibrary",
  mixins: [SocketIOMixin],
  components: {},
  props: {
    exercise: {
      type: Object,
      required: false,
      default: Object,
    },
  },
  data: function () {
    return {
      
    };
  },
  computed: {
    ...mapState("sample", ["sample"]),
  },
  watch: {
    exercise: function (val) {
      if (val._id) {
        this.find();
      }
    },
  },
  created: function () {},
  mounted: function () {
    const that = this;

    // that.listenExerciseList(that.exercise, "sim-cell");

    that.find();

        that.socketIOSubscribe({
            name: `${that.exercise._id}-sim-cell-list`
        });

    // that.subscribe(`${that.exercise._id}-remove-sim-cell-list`, function () {
    //   that.find();
    // });
  },
  methods: {
    find: async function () {
      const that = this;

      const response = await that.$http.get("/sim-cell", {
        params: {
          exercise: that.exercise._id,
        },
      });

      that.itemList = response.data;
    },

    remove: async function (item) {
      const that = this;

      await that.$http.patch(`/sim-cell/${item._id}`, { active: false });
      that.$notify({
        text: "Simcell deleted",
        duration: 10000,
        type: "success",
      });

      that.find();
    },
  },
};
</script>
