// import {
//     HTTP
// } from '@/util/http-common';

import { _ } from "core-js";

import * as _l from 'lodash';

export default {
    namespaced: true,
    state: {
      component: "Exercise",
      showFullScreenModal: false,
      role: {},
      exercise: {currentTime: 0},
      roles: []
    },
    getters: {
      getWidgetComponent: state => {
        return state.component;
      },
      currentTime: state => state.exercise.currentTime,
      // Get the true role id for the current role.
      getRoleID: (state) => {
        return (state.role.role) ? state.role.role : state.role._id;
      },
      getRoleIDFromID: (state) => (_id) => {
        const selectedRole = _l.find(state.roles, (role) => {
          return _id == role._id;
        });

        return (selectedRole.role) ? selectedRole.role : selectedRole._id;
      },
      getActiveRoles: (state) => {
        return state.roles.filter((role) => {
          return !_l.isNumber(role.deletedTime) && !_l.isNumber(role.actionTime);
        });
      },
      getTraineeRoles: (state, getters) => {
        return getters.getActiveRoles.filter((role) => {
          return !role.controller;
        });
      },
      getTraineeRolesExceptMe: (state, getters) => {
        return getters.getTraineeRoles.filter((role) => {
          return !(role.role == state.role.role || role.role == state.role._id);
        });
      }
    },
    mutations: {
      setWidgetComponent(state, component) {
        state.component = component;
      },
      fullScreenWidget(state, widget) {
        console.log('Adding widget: ', widget);
          state.component = widget;
          state.showFullScreenModal = true;
      },
      exitFullScreen(state) {
          state.showFullScreenModal = false;
      },
      setRole(state, role) {
          role.permissionList = [];

          _l.each(role.permissions, (permission) => {
            role.permissionList.push(permission.slug);
          });

          state.role = role;
          
          if(role._id) {
            localStorage.setItem(`${state.exercise._id}-role`, JSON.stringify(role));
          } else {
            localStorage.removeItem(`${state.exercise._id}-role`);
          }
      },
      setExercise(state, exercise) {
          state.exercise = exercise;
      },
      setExerciseCurrentTime(state, currentTime) {
          state.exercise.currentTime = currentTime;
      },
      updateRoles(state, roles) {
        _l.each(roles, (role) => {
          const idx = _l.findIndex(state.roles, (it) => {
            return it._id == role._id;
          });

          if(idx >= 0) {
            state.roles[idx] = role;
          } else {
            state.roles.push(role);
          }
        });
      }
    },
    actions: {
      setWidgetComponent({ commit }, component) {
        commit("setWidgetComponent", component);
      },
      fullScreenWidget({ commit }, widget) {
          commit("fullScreenWidget", widget);
      },
      exitFullScreen({ commit }) {
          commit('exitFullScreen');
      }
    }
  };
  